/**
 * @namespace Negotiation
 * Reaction hook to fetch all negotiations for a company
 *
 */

import { get, keyBy, uniqBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import useCompany from '@/shared/hooks/useCompany/useCompany';
import { dispatchToast } from '@/utils/tryOrCatchMessageError';

import { CONTRACT_STATUS } from '@/modules/contract';
import { Benefit, Negotiation, NegotiationById, NegotiationSearchParams } from '../../entities';
import NegotiationService from '../../services/negotiationService';

/**
 * Hook return interface of a beneficiary's information in addition to methods for its use.
 *
 * @interface UseNegotiationsParams
 * @member Negotiation
 */

export interface UseNegotiationsProps {
  companyId?: string;
  onlyActiveContract?: boolean;
}

const negotiationsService = new NegotiationService();

const useNegotiations = (props?: UseNegotiationsProps) => {
  const { company } = useCompany();
  const { companyId = company.id } = props || {};
  const [params, setParams] = useState<NegotiationSearchParams>({});
  const [isLoadingNegotiations, setLoadingNegotiations] = useState<boolean>(false);
  const [negotiations, setNegotiations] = useState<Negotiation[]>([]);
  const [negotiationsById, setNegotiationsById] = useState<NegotiationById>({});

  const [benefits, setBenefits] = useState<Benefit[]>([]);

  const extractRelationships = (negotiations: Negotiation[]): void => {
    const benefits: Benefit[] = [];

    negotiations.forEach((negotiation) => {
      const benefit = get(negotiation, 'product.benefit');

      benefits.push(benefit);
    });

    setBenefits(uniqBy(benefits, 'baseId'));
  };

  const getNegotiationsIdByBenefitId = (benefitId: string): Negotiation[] => {
    return negotiations.filter((negotiation: Negotiation) => {
      const id = get(negotiation, 'product.benefit.id', '');
      return id === benefitId;
    });
  };

  const getBenefitByNegotiationId = (negotiationId: string): Benefit | undefined => {
    const negotiationFound = negotiations.find((negotiation) => negotiation.id === negotiationId);
    return get(negotiationFound, 'product.benefit', undefined);
  };

  const loadNegotiations = useCallback(
    async (companyIdToFilter: string) => {
      if (!companyId) {
        return;
      }

      setLoadingNegotiations(true);

      const negotiationSearchParams: NegotiationSearchParams = {
        ...params,
        'costCenter.companyId': companyIdToFilter,
      };

      try {
        const { data } = await negotiationsService.getAllNegotiationsByCompanyId<Negotiation>(
          companyId,
          negotiationSearchParams,
        );
        let filtredData = data;
        if (props?.onlyActiveContract) {
          filtredData = data.filter(
            (negotiation) => negotiation.contract.status === CONTRACT_STATUS.ACTIVE,
          );
        }
        setNegotiations(filtredData);
        setNegotiationsById(keyBy(filtredData, 'id'));
        extractRelationships(filtredData);
      } catch (e) {
        console.error(e);

        dispatchToast({
          text: 'Erro ao carregar o negociações da empresa.',
        });
      } finally {
        setLoadingNegotiations(false);
      }
    },
    [companyId, params, props?.onlyActiveContract],
  );

  const handleParams = useCallback(
    (filters?: NegotiationSearchParams) => {
      setParams({
        ...params,
        ...filters,
      });
    },
    [params],
  );

  useEffect(() => {
    if (!companyId) {
      return;
    }

    loadNegotiations(companyId);
  }, [loadNegotiations, params, company.id, companyId]);

  return {
    handleParams,
    isLoadingNegotiations,
    negotiations,
    negotiationsById,
    benefits,
    loadNegotiations,
    getNegotiationsIdByBenefitId,
    getBenefitByNegotiationId,
  };
};

export default useNegotiations;
