import DataGrid from '@/components/dataGrid/dataGrid';
import { Status } from '@/modules/beneficiary/components';
import {
  BENEFICIARY_STATUS_ALIAS_TRANSLATED,
  OWNERSHIP_TRANSLATED,
} from '@/modules/beneficiary/entities/constants';
import {
  BeneficiaryResponseNormalized,
  BeneficiarySearchParams,
} from '@/modules/beneficiary/entities/interfaces';
import BeneficiaryService from '@/modules/beneficiary/services/beneficiaryService';
import { useCompanyPropagate } from '@/modules/company/hooks/useCompanyPropagate';
import CompaniesService from '@/modules/company/services/companiesService';
import NegotiationService from '@/modules/negotiation/services/negotiationService';
import { Company } from '@/services/negotiations/endpoints/companies';
import { LIST_INITIAL_PAGE, LIST_INITIAL_PAGE_SIZE } from '@/shared/entities/constants/list';
import { useQueryStringState } from '@/shared/hooks/useQueryStringState';
import ICONS from '@/shareds/constants/icons';
import { Negotiation } from '@/shareds/types/negotiation.type';
import { get, keyBy } from 'lodash';
import React, { useCallback } from 'react';

interface BeneficiaryQueryParams extends BeneficiarySearchParams {
  showMoreFilters?: boolean;
}

const negotiationsService = new NegotiationService();
const companiesService = new CompaniesService();

const GlobalList: React.FC = () => {
  const { propagateCompany } = useCompanyPropagate()
  const [filters, setFilters] = useQueryStringState<BeneficiaryQueryParams>({
    page: LIST_INITIAL_PAGE,
    pageSize: LIST_INITIAL_PAGE_SIZE,
    status: 'ACTIVE',
    sort: 'life.name',
  });

  const getMapCompanyGroupByNegotiationId = useCallback(async (negotiations: Negotiation[]) => {
    const salesforceCompanyIds =  negotiations.reduce((acc: string[], negotiation) => {
      const company = get(negotiation, 'costCenter.company');
      const salesforceCompanyId = company?.salesforceParentId || company?.salesforceId;

      if (salesforceCompanyId) {
        acc.push(salesforceCompanyId);
      }

      return acc;
    }, []);

    const { data: companyGroups } = await companiesService.getCompanies({ salesforceId: salesforceCompanyIds });

    return negotiations.reduce((acc: Record<string, Company>, negotiation) => {
      const company = get(negotiation, 'costCenter.company');
      const salesforceCompanyId = company?.salesforceParentId || company?.salesforceId;

      const companyGroup = companyGroups.find(companyGroup => companyGroup.salesforceId === salesforceCompanyId);
      if (negotiation.id && companyGroup) {
        acc[negotiation.id] = companyGroup;
      }

      return acc;
    }, {})
  }, [])

  const getBeneficiaries = useCallback(
    async (filters: BeneficiarySearchParams) => {
      const { data: beneficiaries, ...pagination } =
        await new BeneficiaryService().getBeneficiaries<BeneficiaryResponseNormalized>(filters);

      if (pagination.total === 0) {
        return {
          ...pagination,
          data: [],
        }
      }

      const { data: negotiations } = await negotiationsService.getAllNegotiations({
        id: beneficiaries.map((beneficiary) => beneficiary.negotiationId),
      });

      const negotiationById = keyBy(negotiations, 'id');
      const companyGroupByNegotiationId = await getMapCompanyGroupByNegotiationId(negotiations);

      return {
        ...pagination,
        data: beneficiaries.map((beneficiary) => {
          return {
            ...beneficiary,
            negotiation: negotiationById[beneficiary.negotiationId],
            companyGroup: companyGroupByNegotiationId[beneficiary.negotiationId],
          };
        }),
      };
    },
    [getMapCompanyGroupByNegotiationId],
  );

  const redirectToBeneficiaryDetails = useCallback(async (
    { id, companyId, negotiation }: { id: string, companyId: string; negotiation: Negotiation }
  ) => {
    let company = negotiation.costCenter?.company

    if (!company) {
      const { data } = await companiesService.getCompanies({ companyId });
      company = data[0];
    }

    propagateCompany(company);
    location.href = `/companies/${companyId}/beneficiaries/${id}/details`;
  }, [propagateCompany])

  return (
    <DataGrid
      filterStateController={[filters, setFilters]}
      source={getBeneficiaries}
      showSelectPageSize={false}
      filters={[
        {
          label: 'Nome',
          key: 'name',
          width: 25,
        },
        {
          label: 'CPF',
          key: 'cpf',
          width: 25,
        },
        {
          label: 'Status',
          key: 'status',
          type: 'select',
          width: 25,
          data: Object.entries(BENEFICIARY_STATUS_ALIAS_TRANSLATED).map(([key, val]) => ({
            text: `${val}s`,
            value: key,
          })),
        },
        {
          label: 'Tipo',
          key: 'type',
          type: 'select',
          width: 25,
          data: Object.entries(OWNERSHIP_TRANSLATED).map(([key, val]) => ({
            text: val,
            value: key,
          })),
        },
      ]}
      columns={[
        {
          headerName: 'Nome',
          field: 'life.name',
          sup: {
            field: 'ownership',
            textParams: ({ isHolder }) => ({
              color: isHolder ? 'success' : 'colorDefault',
            }),
          },
        },
        {
          headerName: 'CPF',
          field: 'life.cpf',
          width: '140px',
        },
        {
          headerName: 'Benefício',
          field: 'negotiation.product.benefit.name',
        },
        {
          headerName: 'Empresa',
          field: 'negotiation.costCenter.company.name',
          sub: {
            field: 'negotiation.costCenter.company.cnpj',
            formatData: 'cnpj'
          }
        },
        {
          headerName: 'Grupo',
          field: 'companyGroup.name',
          sub: {
            field: 'companyGroup.cnpj',
            formatData: 'cnpj'
          }
        },
        {
          headerName: 'Status',
          render: ({ status }) => <Status label={status} />,
        },
      ]}
      actionsByItem={[
        {
          icon: ICONS.DETAILS,
          label: 'Ver detalhes',
          onClick: redirectToBeneficiaryDetails,
        },
      ]}
    />
  );
};

export default GlobalList;
