import React from 'react';

import { BoxInfo, TextData } from '@/components';
import { LifeNormalized } from '../../entities';

type LifeInformationProps = {
  life?: Partial<LifeNormalized>;
};

const LifeInformation: React.FC<LifeInformationProps> = ({ life }) => (
  <BoxInfo title='Dados pessoais' icon='info-outline'>
    <TextData
      loading={!life}
      data={[
        {
          label: 'Data de nascimento',
          text: life?.birthDate,
          type: 'date',
        },
        {
          label: 'RG',
          abbreviationOf: 'Carteira de Identidade ou Registro Geral',
          text: life?.rg,
          type: 'rg',
        },
        {
          label: 'CPF',
          abbreviationOf: 'Cadastro de Pessoa Física',
          text: life?.cpf,
          type: 'cpf',
        },
        {
          label: 'CNS',
          abbreviationOf: 'Cartão Nacional de Saúde',
          text: life?.cns,
        },
        {
          label: 'Estado civil',
          text: life?.maritalStatus,
          type: 'marital',
        },
        {
          label: 'Sexo',
          text: life?.gender,
        },
        {
          label: 'Nome da mãe',
          text: life?.motherName,
          sizeDefault: 100,
          sizeMedium: 100,
        },
      ]}
    />
  </BoxInfo>
);

export default LifeInformation;
