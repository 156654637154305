import { get } from 'lodash';
import React, { useCallback } from 'react';
import { Button } from 'vkit/lib/components';
import { BoxInfo, EmptyMessage } from '@/components';
import { Action, Column } from '@/components/dataTable';
import { ContentListLines } from '@/components/dataTable/components';
import { PlanWithNegotiation } from '@/modules/plan/entities/plan';
import { useHistoryLocation, useHistoryNavigator } from '@/navigation';
import { isOmni } from '@/pages/beneficiaries/shareds/utils/checkProvider';
import { Product } from '@/services/negotiations/endpoints/products';
import ICONS from '@/shareds/constants/icons';

export type ColumnNames = 'name' | 'ansCode' | 'accommodation' | 'coverage' | 'costCenter';
export type ActionNames = 'goToDetails';

interface ListProps {
  columns: ColumnNames[];
  actions?: ActionNames[];
  linesLoader?: number;
  loading?: boolean;
  data: PlanWithNegotiation[];
  elevation?: number;
}

const PlanList: React.FC<ListProps> = ({
  columns,
  actions,
  linesLoader,
  loading,
  data,
  elevation = 4,
}) => {
  const navigate = useHistoryNavigator();
  const location = useHistoryLocation();
  const columnsToChoose: { [key in ColumnNames]: Column } = {
    name: {
      title: 'PLANO',
      custom: (plan: PlanWithNegotiation) => {
        return isOmni(plan.negotiation) ? plan.name : plan.providerInternalCode || plan.name;
      },
    },
    ansCode: {
      title: 'CÓDIGO ANS',
      path: 'ansCode',
    },
    costCenter: {
      title: 'CENTRO DE CUSTO',
      path: 'negotiation.costCenter.name',
    },
    accommodation: {
      title: 'ACOMODAÇÃO',
      path: 'accommodation.name',
    },
    coverage: {
      title: 'COBERTURA',
      path: 'coverage.name',
    },
  };

  const getActions = useCallback(
    (plan: Product | PlanWithNegotiation) => {
      const negotiationId = get(plan, 'negotiation.id');

      const actionsToChoose: { [key in ActionNames]: Action } = {
        goToDetails: {
          label: 'Ver detalhes',
          onClick: () => navigate.push(`${location.pathname}/plan?negotiationId=${negotiationId}`),
          icon: ICONS.DETAILS,
        },
      };

      if (actions?.length === 1) {
        const [actionKey] = actions;
        const action = actionsToChoose[actionKey];

        return <Button solo outlined label={action.label} onClick={action.onClick as () => void} />;
      }

      return actions?.map((actionKey) => actionsToChoose[actionKey]);
    },
    [actions, location.pathname, navigate],
  );

  return (
    <BoxInfo elevation={elevation} margin={0} padding={1}>
      {data.length || loading ? (
        <ContentListLines
          actionsByLine={actions && getActions}
          columns={columns.map((column) => columnsToChoose[column])}
          data={data}
          linesLoader={linesLoader || 10}
          loading={loading}
        />
      ) : null}

      {!data.length && !loading ? (
        <EmptyMessage title='Nada por aqui!' description='Nenhum registro encontrado.' />
      ) : null}
    </BoxInfo>
  );
};

export default PlanList;
