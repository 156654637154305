import Banner from '@/modules/assetImage/components/banner';
import { BenefitsBox } from '@/modules/benefit/views';
import ButtonInsights from '@/modules/company/components/buttonInsights/buttonInsights';
import About from '@/modules/company/views/about/about';
import LastMove from '@/modules/move/views/lastMove/LastMove';
import React from 'react';
import { Grid, Row } from 'vkit/lib/context';

const Home: React.FC = () => {
  return (
    <>
      <Row style={{ padding: 0 }}>
        <Grid column gap={16}>
          <Row style={{ padding: 0 }}>
            <Grid alignContent='justify'>
              <LastMove />

              <Grid alignContent='right' growing>
                <ButtonInsights />
              </Grid>
            </Grid>
          </Row>
          <BenefitsBox />
        </Grid>
      </Row>

      <Banner />

      <About />
    </>
  );
};

export default Home;
