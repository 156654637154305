import { maskCpf } from '@/utils/format';
import { GENDER_TRANSLATE, LifeGender } from '../entities';
import { Document, Life, LifeNormalized } from '../entities/interfaces/life';

export enum LIFE_DOCUMENT_TYPE {
  CPF = 'cpf',
  RG = 'rg',
  CARTAO_SUS = 'cartao_sus',
}

export const normalizeLifeDocuments = (documents: Document[]) => {
  let cpf = '-';
  let rg = '-';
  let cns = '-';

  documents.forEach((document) => {
    if (document) {
      if (document.type === 'cpf') {
        cpf = maskCpf(document.value);
      }
      if (document.type === 'rg') {
        rg = document.value;
      }
      if (document.type === 'cartao_sus') {
        cns = document.value;
      }
    }
  });

  return {
    cpf,
    rg,
    cns,
  };
};

export const normalizeLife = (life: Life): Partial<LifeNormalized> => {
  if (!life) {
    return {};
  }
  const { birthDate, gender, motherName, documents } = life;
  const { cpf, rg, cns } = normalizeLifeDocuments(documents || []);
  return {
    ...life,
    birthDate,
    gender: GENDER_TRANSLATE[gender] as LifeGender,
    motherName: motherName || '-',
    cpf,
    rg,
    cns,
  };
};
