import { usePaginatedList } from '@/components/dataGrid/hooks/useRequestData/usePaginatedList';
import BannerEdition from '@/modules/assetImage/components/bannerEdition';
import BannerViwer from '@/modules/assetImage/components/bannerViwer';
import { ASSET_IMAGE_TYPE } from '@/modules/assetImage/entities/enums';
import AssetImagesService from '@/modules/assetImage/services/assetImagesService';
import useCurrentUser from '@/modules/profile/hooks/useCurrentUser/useCurrentUser';
import { USER_PERMISSIONS } from '@/modules/user';
import React from 'react';

const Banner: React.FC = () => {
  const { checkUserHasSomePermission } = useCurrentUser();

  const {
    data: [assetImage],
    loading,
    loadData,
  } = usePaginatedList({
    source: () =>
      new AssetImagesService().listAssetImages({
        pageSize: 1,
        type: ASSET_IMAGE_TYPE.BANNER,
        sort: '-initialDate',
      }),
  });

  return checkUserHasSomePermission([
    USER_PERMISSIONS.ASSET_IMAGE_CREATE,
    USER_PERMISSIONS.ASSET_IMAGE_UPDATE,
    USER_PERMISSIONS.ASSET_IMAGE_DELETE,
  ]) ? (
    <BannerEdition assetImage={assetImage} loading={loading} refreshData={loadData} />
  ) : (
    <BannerViwer assetImage={assetImage} />
  );
};

export default Banner;
