/**
 * API URL Configuration
 *
 * @version 2.0.0
 * @namespace httpClient
 *
 * This file contains the configuration for API URLs used in the application.
 * It defines environment variables for each API URL and exports them as a frozen object.
 * The exported object provides a convenient way to access the API URLs throughout the application.
 */

const {
  // Define environment variables for API URLs
  REACT_APP_API_AUTH_URL = '',
  REACT_APP_ORIGIN_URL_USER = '',
  REACT_APP_API_NEGOTIATIONS_URL = '',
  REACT_APP_API_BENEFICIARIES_URL = '',
  REACT_APP_API_MOVES_URL = '',
  REACT_APP_API_FILES_URL = '',
  REACT_APP_API_MONITORING_URL = '',
  REACT_APP_API_SALESFORCE_GATEWAY_URL = '',
  REACT_APP_API_SEARCH_ADDRESS = '',
  REACT_APP_API_DATA_TEAM_URL = '',
  REACT_APP_API_SYSTEM_URL = '',
} = process.env;

/**
 * API_URL object that contains the defined API URLs.
 * Each key represents a specific API endpoint.
 */
export const DOMAIN_URL = Object.freeze({
  AUTH: REACT_APP_API_AUTH_URL,
  USER: REACT_APP_ORIGIN_URL_USER,
  NEGOTIATION: REACT_APP_API_NEGOTIATIONS_URL,
  BENEFICIARY: REACT_APP_API_BENEFICIARIES_URL,
  MOVES: REACT_APP_API_MOVES_URL,
  FILES: REACT_APP_API_FILES_URL,
  MONITORING: REACT_APP_API_MONITORING_URL,
  SALESFORCE: REACT_APP_API_SALESFORCE_GATEWAY_URL,
  ADDRESS: REACT_APP_API_SEARCH_ADDRESS,
  DATA_TEAM: REACT_APP_API_DATA_TEAM_URL,
  SYSTEM: REACT_APP_API_SYSTEM_URL,
});

/**
 * Type representing the keys of the API_URL object.
 */
export type DomainKey = keyof typeof DOMAIN_URL;
