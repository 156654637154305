import { Markup } from '@/modules/contract/entities/over';
import restfulBase from '@/services/negotiations/core/base';
import { contractInitialState } from '@/services/negotiations/endpoints/contracts';
import { CostCenter, costCenterInitialState } from '@/services/negotiations/endpoints/costCenter';
import { Product, productInitialState } from '@/services/negotiations/endpoints/products';
import { Base } from '@/shareds/types/base.type';
import { Contract } from '@/modules/contract';

export interface Negotiation extends Base {
  contractId: string;
  productId: string;
  brokerId: string;
  costCenterId: string;
  reimbursement: number;
  product: Product;
  contract: Contract;
  costCenter: CostCenter;
  markups: Markup[];
}

export const negotiationInitalState: Negotiation = {
  contractId: '',
  productId: '',
  brokerId: '',
  costCenterId: '',
  reimbursement: 0,
  product: productInitialState,
  contract: contractInitialState,
  costCenter: costCenterInitialState,
  markups: [],
};

export const negotiationsService = (companyId: string) =>
  restfulBase(`companies/${companyId}/negotiations`);

export default negotiationsService;
