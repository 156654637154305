import { TextFieldValidate, ToastStatusGlobal } from '@/components';
import Divider from '@/components/divider';
import FormValidate, { BodyForm } from '@/components/formValidate';
import { FormValidateHandler } from '@/components/formValidate/types/formValidate.type';
import GridGroup from '@/components/webForm/gridGroup';
import BannerUpload from '@/modules/assetImage/components/bannerUpload';
import { AssetImage } from '@/modules/assetImage/entities/interfaces';
import { useBannerValidation } from '@/modules/assetImage/hooks/useBannerValidation';
import { useChangeState } from '@/shared/hooks/useChangeState';
import ICONS from '@/shareds/constants/icons';
import React, { Ref, useCallback, useState } from 'react';

interface BannerFormProps {
  banner?: AssetImage;
  refreshData?: () => void;
  setOpen: (open: boolean) => void;
  refForm?: Ref<FormValidateHandler>;
  requestCreation?: (entity: AssetImage) => Promise<void>;
  requestUpdate?: (id: number, entity: Partial<AssetImage>) => Promise<void>;
}

const BannerForm: React.FC<BannerFormProps> = ({
  banner: bannerToUpdate,
  requestCreation,
  requestUpdate,
  refForm,
}) => {
  const [banner, setBanner] = useState<Partial<AssetImage>>({
    ...bannerToUpdate,
    type: 'banner',
  });
  const changeBanner = useChangeState(setBanner);
  const { toast } = ToastStatusGlobal();
  const { isBeforeEndDate, isAfterNow, isAfterInitialDate } = useBannerValidation({
    banner,
  });

  const formBody = useCallback(({ useValues, useErrors, onBlur, onChange }: BodyForm) => (
    <GridGroup
      body={[
        {
          default: 100,
          middle: 100,
          component: (
            <BannerUpload
              name='file'
              imageUrl={bannerToUpdate?.url}
              onBlur={onBlur}
              onChange={onChange}
              useErrors={useErrors}
            />
          ),
        },
        {
          default: 100,
          middle: 100,
          component: <Divider space={0} />,
        },
        {
          default: 100,
          middle: 100,
          component: (
            <TextFieldValidate
              label='Nome'
              name='name'
              useValues={useValues}
              useErrors={useErrors}
              onChange={onChange}
              onBlur={onBlur}
              maxLength={300}
            />
          ),
        },
        {
          default: 100,
          middle: 100,
          component: (
            <TextFieldValidate
              label='Link'
              name='link'
              useValues={useValues}
              useErrors={useErrors}
              onChange={onChange}
              onBlur={onBlur}
              maxLength={300}
              icon={ICONS.LINK}
              required
            />
          ),
        },
        {
          default: 50,
          middle: 100,
          component: (
            <TextFieldValidate
              label='Início da exibição'
              name='initialDate'
              type='date'
              useValues={useValues}
              useErrors={useErrors}
              onChange={onChange}
              onBlur={onBlur}
              required
            />
          ),
        },
        {
          default: 50,
          middle: 100,
          component: (
            <TextFieldValidate
              required
              label='Fim da exibição'
              name='endDate'
              type='date'
              useValues={useValues}
              useErrors={useErrors}
              onChange={onChange}
              onBlur={onBlur}
            />
          ),
        },
      ]}
    />
  ), [bannerToUpdate?.url])

  return (
    <FormValidate
      ref={refForm}
      resource={banner}
      onChangeForm={changeBanner}
      onSubmit={(data: AssetImage) =>
        bannerToUpdate?.id ? requestUpdate?.(bannerToUpdate?.id, data) : requestCreation?.(data)
      }
      onError={() =>
        toast('Puxa!', 'Existem alguns campos com preenchimento irregular.', 'warning')
      }
      fields={{
        file: bannerToUpdate?.id ? [] : ['required'],
        name: [],
        link: ['required'],
        initialDate: ['required', 'date', isBeforeEndDate],
        endDate: ['required', 'date', isAfterNow, isAfterInitialDate],
      }}
      body={formBody}
    />
  );
};

export default BannerForm;
