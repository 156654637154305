import BoxInfo from '@/components/boxInfo';
import EmptyMessage from '@/components/emptyMessage';
import BannerManagerModal from '@/modules/assetImage/components/bannerManagerModal';
import BannerViwer from '@/modules/assetImage/components/bannerViwer';
import { AssetImage } from '@/modules/assetImage/entities/interfaces';
import ICONS from '@/shareds/constants/icons';
import { getFormattedDate } from '@/utils/format';
import React, { useMemo } from 'react';
import { Button } from 'vkit/lib/components';
import { Box } from 'vkit/lib/context';

interface BannerEditProps {
  assetImage?: AssetImage;
  loading: boolean;
  refreshData?: () => void;
}

const BannerEdition: React.FC<BannerEditProps> = ({ assetImage, loading, refreshData }) => {
  const [openModal, setOpenModal] = React.useState(false);
  const isBeforeNow = useMemo(() => assetImage ? new Date(assetImage.initialDate).getTime() <= new Date().getTime() : false, [assetImage]);
  const expiredDate = useMemo(() => assetImage?.endDate ? new Date(assetImage.endDate).getTime() < new Date().getTime() : false, [assetImage]);

  return (
    <>
      <BannerManagerModal
        open={openModal}
        setOpen={setOpenModal}
        assetImage={assetImage}
        refreshData={refreshData}
      />
      <BoxInfo
        loading={loading}
        title='Banner'
        icon={ICONS.INFO}
        header={<Button label='Gerenciar' onClick={() => setOpenModal(!openModal)} />}>
        {
          assetImage && !expiredDate && isBeforeNow && (
            <BannerViwer elevation={1} margin={0} assetImage={assetImage} />
          )
        }
        {
          assetImage && !expiredDate && !isBeforeNow && (
            <Box type='card' rounded outlined>
              <EmptyMessage
                title='Exibição do banner agendado!'
                description={`A exibição do banner está agendado para ${getFormattedDate(assetImage.initialDate)}`}
              />
            </Box>
          )
        }
        {
          assetImage && expiredDate && assetImage.endDate && (
            <Box type='card' rounded outlined>
              <EmptyMessage
                title='Data de exibição expirada!'
                description={`A exibição do banner expirou em ${getFormattedDate(assetImage.endDate)}`}
              />
            </Box>
          )
        }
        {
          !assetImage && (
            <Box type='card' rounded outlined>
              <EmptyMessage
                title='Nemhum banner encontrado!'
                description='Clique no botão <b>Gerenciar</b> para incluir um banner.'
              />
            </Box>
          )
        }
      </BoxInfo>
    </>
  );
};

export default BannerEdition;
