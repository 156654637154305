/**
 * @version 2.0.0
 * @namespace HttpClient
 *
 * @description
 * HttpClientAdapter is an adapter class that implements the IHttpClient interface.
 * It provides methods for making HTTP requests using Axios as the HTTP client.
 * This class is responsible for configuring the Axios client and performing the requests.
 * It can be extended to customize URL construction, request configurations, and add request and response interceptors.
 */

import {
  WAIT_LOGIN_TIMEOUT_IN_MILLISECONDS_DEFAULT,
  waitLogin,
} from '@/modules/authentication/utils/waitLogin';
import { isAuthenticated } from '@/utils/authentication';
import { dispatchEvent } from '@/utils/dispatchEvent/dispatchEvent';
import { dispatchToast } from '@/utils/tryOrCatchMessageError';
import { DomainKey } from './domain';
import {
  IHttpClient,
  RequestOptions,
  RequestError,
  RequestPayload,
  ResponseData,
  ResponseObjectWithList,
} from './httpClient.interface';
import HttpClientConfig from './httpClientConfig';

class HttpClientAdapter extends HttpClientConfig implements IHttpClient {
  private path: string;

  /**
   * Constructs an instance of HttpClientAdapter.
   * @param domainKey The API key to be used.
   */
  constructor(domainKey: DomainKey, path: string = '') {
    super(domainKey);
    this.path = path;
  }

  private async treatError(error: RequestError) {
    if (error?.response?.status === 401) {
      dispatchEvent('modal-login', { open: true });
      await waitLogin({ timeoutInMilliseconds: WAIT_LOGIN_TIMEOUT_IN_MILLISECONDS_DEFAULT });
      if (isAuthenticated()) {
        const response = await this.getAxiosInstance().request(error.options);
        return response.data;
      }
    }

    if (error?.response?.status === 408) {
      dispatchToast({
        text: 'Ops! Problemas de conexão. recarregue a página.',
        showJustOne: true,
      });
    }

    if (error.code === 'ECONNABORTED') {
      dispatchToast({
        text: 'Ops! Problemas de conexão. Verifique sua internet e recarregue a página.',
        showJustOne: true,
      });
    }

    throw error;
  }

  async request<T>(options: RequestOptions): Promise<T> {
    const requestConfig = this.getRequestConfig(options.config);
    const url = this.getFullURL(options.path);
    try {
      const response = await this.getAxiosInstance().request({
        ...requestConfig,
        url,
        params: options.params,
        data: options.data,
        method: options.method,
      });

      return response.data;
    } catch (error) {
      return this.treatError(error as RequestError);
    }
  }

  /**
   * Makes an HTTP GET request.
   * @param path The complement of url for the request.
   * @param params The request parameters.
   * @param config The request configuration.
   * @returns A Promise containing the response data.
   */
  get<Response = any, Params = any>(
    path: string,
    params?: Params,
    config?: Partial<RequestOptions>,
  ): Promise<ResponseData<Response>> {
    return this.request<ResponseData<Response>>({
      path,
      params,
      method: 'get',
      config,
    });
  }

  /**
   * Fetches data from multiple pages of a paginated API endpoint.
   * @param path The endpoint URL without query parameters.
   * @param params Optional query parameters for the request.
   * @param config Optional request configuration for multiple requests.
   *   - listKey: The key to access the array of data in the response.
   *   - totalPagesKey: The key to access the total number of pages in the response.
   *   - pageKey: The key to specify the page number in the query parameters.
   * @returns A Promise containing the aggregated response data from all pages.
   */
  async getAll<Response = any, Params = any>(
    path: string,
    params?: Params,
    config?: Partial<RequestOptions>,
  ): Promise<ResponseObjectWithList<Response>> {
    const results = await this.get(path, params, config);
    const listKey = config?.listKey || 'data';
    const totalPagesKey = config?.totalPagesKey || 'totalPages';
    const totalPages = results[totalPagesKey];
    const list: Response[] = results[listKey];

    const pages = Array.from({ length: totalPages - 1 }, (_, num) => num + 2);

    if (list?.length < 10) {
      return {
        [listKey]: list,
      };
    }

    for (const page of pages) {
      const resultsFromNextPage = await this.get(
        path,
        {
          ...params,
          [config?.pageKey || 'page']: [page],
        },
        config,
      );

      list.push(...(resultsFromNextPage[listKey] || []));
    }

    return {
      [listKey]: list,
    };
  }

  /**
   * Makes an HTTP POST request.
   * @param path The complement of url for the request.
   * @param data The request data.
   * @param config The request configuration.
   * @returns A Promise containing the response data.
   */
  post<T = any, D = any>(
    path: string,
    data?: RequestPayload<D>,
    config?: Partial<RequestOptions>,
  ): Promise<ResponseData<T>> {
    return this.request<ResponseData<T>>({
      path,
      data,
      method: 'post',
      config,
    });
  }

  /**
   * Makes an HTTP PUT request.
   * @param path The complement of url for the request.
   * @param data The request data.
   * @param config The request configuration.
   * @returns A Promise containing the response data.
   */
  put<T = any>(
    path: string,
    data?: RequestPayload<T>,
    config?: Partial<RequestOptions>,
  ): Promise<ResponseData<T>> {
    return this.request<ResponseData<T>>({
      path,
      data,
      method: 'put',
      config,
    });
  }

  /**
   * Makes an HTTP PATCH request.
   * @param path The complement of url for the request.
   * @param data The request data.
   * @param config The request configuration.
   * @returns A Promise containing the response data.
   */
  patch<T = any>(
    path: string,
    data?: RequestPayload<T>,
    config?: Partial<RequestOptions>,
  ): Promise<ResponseData<T>> {
    return this.request<ResponseData<T>>({
      path,
      data,
      method: 'patch',
      config,
    });
  }

  /**
   * Makes an HTTP DELETE request.
   * @param path The complement of url for the request.
   * @param config The request configuration.
   * @returns A Promise containing the response data.
   */
  delete<T = any>(path: string, config?: Partial<RequestOptions>): Promise<ResponseData<T>> {
    return this.request<ResponseData<T>>({
      path,
      method: 'delete',
      config,
    });
  }

  /**
   * Builds the complete URL for the request.
   * @param url The complement of url of the request.
   * @returns The complete URL for the request.
   * @protected
   */
  protected getFullURL(url: string): string {
    // Here you can add logic to customize the URL without modifying the base URL
    return `${super.getDomain()}${this.path}${url}`;
  }

  /**
   * Gets the request configuration.
   * @param config The request configuration.
   * @returns The request configuration.
   * @protected
   */
  protected getRequestConfig(config?: RequestOptions): RequestOptions {
    // Aqui você pode adicionar qualquer configuração adicional necessária
    const axiosConfig: RequestOptions = {
      // Configurações padrão
      ...config,
      // Adicione o transformador de resposta
      transformResponse: [...[this.defaultTransformResponse], ...(config?.transformResponse || [])],
    };
    return axiosConfig;
  }

  protected defaultTransformResponse(data: any) {
    if (!data || data instanceof Blob) {
      return data;
    }

    const parsed = JSON.parse(data);
    if (parsed?.page) {
      return parsed;
    } else {
      if (!parsed.data) {
        return parsed;
      }
      return parsed.data;
    }
  }
}

export default HttpClientAdapter;
