import { APPLICATION_GROUP_BY, Route } from '@/core/entities';
import { createRouteLoaderPageDetails } from '@/core/utils/routes.utils';
import Main from '@/core/views/main/main';
import GlobalList from '@/modules/beneficiary/pages/globalList/globalList';

import DetailsMoves from '@/modules/move/pages/details/details';
import ListMoves from '@/modules/move/pages/list/list';
import CreateOrUpdate from '@/pages/beneficiaries/createOrUpdate/createOrUpdate';
import Removal from '@/pages/beneficiaries/removal/removal';

import SuccessMove from '@/pages/moves/success/success';
import UploadMove from '@/pages/moves/upload/upload';
import { USER_TYPE } from '@/services/users/endpoints/users';
import ICONS from '@/shareds/constants/icons';
import { Details, Home, List } from '../pages';

export enum ROUTES_BENEFICIARY {
  LIST = 'beneficiary-list',
  GLOBAL_LIST = 'beneficiary-global-list',
  DETAIL = 'beneficiary-detail',
  CREATE = 'beneficiary-create',
  UPDATE = 'beneficiary-update',
  REMOVE = 'beneficiary-remove',
}

export enum ROUTE_MOVES {
  LIST = 'moves-list',
  DETAIL = 'moves-list-detail',
  UPLOAD = 'moves-upload',
  UPLOAD_SUCCESS = 'moves-upload-success',
}

const Routes: Route[] = [
  {
    path: '/companies/:companyId/beneficiaries',
    Component: Home,
    id: ROUTES_BENEFICIARY.LIST,

    meta: {
      groupedBy: APPLICATION_GROUP_BY.COMPANY,
      showApplicationMenu: true,
      nameMenu: 'Beneficiários',
      icon: 'people-outline',
    },
    loader() {
      return {
        title: 'Beneficiários',
      };
    },

    children: [
      {
        index: true,
        Component: List,
      },
      {
        id: ROUTES_BENEFICIARY.DETAIL,
        Component: Details,
        path: ':beneficiaryId/details',
        loader() {
          return {
            name: 'Detalhes do Beneficiário',
            icon: 'people-outline',
            hasGoBackAction: true,
            title: 'Beneficiários | Detalhes',
          };
        },
      },
      {
        id: ROUTES_BENEFICIARY.UPDATE,
        Component: CreateOrUpdate,
        path: ':beneficiaryId/update',
        loader() {
          return {
            name: 'Solicitação de alteração de beneficiário',
            icon: 'people-outline',
            hasGoBackAction: true,
            title: 'Beneficiários | Update',
          };
        },
      },
      {
        id: ROUTES_BENEFICIARY.CREATE,
        Component: CreateOrUpdate,
        path: 'create',
        loader() {
          return {
            name: 'Criar beneficiário',
            icon: 'people-outline',
            hasGoBackAction: true,
            title: 'Beneficiários | Inclusão',
          };
        },
      },
      {
        id: ROUTES_BENEFICIARY.REMOVE,
        Component: Removal,
        path: ':beneficiaryId/removal',
        loader() {
          return {
            name: 'Solicitação de exclusão de beneficiário',
            icon: 'people-outline',
            hasGoBackAction: true,
            title: 'Beneficiários | Exclusão',
          };
        },
      },
      {
        path: 'moves',
        id: ROUTE_MOVES.LIST,
        Component: ListMoves,
        loader() {
          return {
            name: 'Lista de movimentações',
            icon: ICONS.MOVE,
            title: 'Movimentações',
          };
        },
      },
      {
        id: ROUTE_MOVES.DETAIL,
        Component: DetailsMoves,
        path: 'moves/:moveId/details',
        loader() {
          return createRouteLoaderPageDetails({
            name: 'Detalhes da movimentação',
            icon: ICONS.MOVE,
            hasGoBackAction: true,
            title: 'Movimentações | Detalhes',
          });
        },
      },
      {
        id: ROUTE_MOVES.UPLOAD,
        Component: UploadMove,
        path: 'moves/actions/upload',
        loader() {
          return createRouteLoaderPageDetails({
            hasGoBackAction: true,
            title: 'Movimentações | Upload',
          });
        },
      },
      {
        id: ROUTE_MOVES.UPLOAD_SUCCESS,
        Component: SuccessMove,
        path: 'moves/success',
        loader() {
          return createRouteLoaderPageDetails({
            hasGoBackAction: true,
            title: 'Movimentações | Sucesso',
          });
        },
      },
    ],
  },
  {
    path: '/beneficiaries',
    Component: Main,
    id: ROUTES_BENEFICIARY.GLOBAL_LIST,

    meta: {
      groupedBy: APPLICATION_GROUP_BY.BROKER,
      showApplicationMenu: true,
      icon: ICONS.BENEFICIARIES,
      nameMenu: 'Busca de beneficiários',
      permission: {
        type: USER_TYPE.BROKER,
      },
    },
    loader() {
      return createRouteLoaderPageDetails({
        groupedBy: APPLICATION_GROUP_BY.BROKER,
        icon: ICONS.BENEFICIARIES,
        name: 'Beneficiários',
        title: 'Beneficiários',
      });
    },

    children: [
      {
        index: true,
        Component: GlobalList,
      },
    ],
  }
];

export default Routes;
