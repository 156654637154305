import { ToastStatusGlobal } from '@/components';
import ButtonDialog from '@/components/buttonDialog';
import { FormValidateHandler } from '@/components/formValidate/types/formValidate.type';
import BannerForm from '@/modules/assetImage/components/bannerForm';
import { AssetImage } from '@/modules/assetImage/entities/interfaces';
import AssetImagesService from '@/modules/assetImage/services/assetImagesService';
import useCurrentUser from '@/modules/profile/hooks/useCurrentUser/useCurrentUser';
import { USER_PERMISSIONS } from '@/modules/user';
import { useRequestCreation } from '@/shared/hooks/useRequestData/useRequestCreation';
import { useRequestDeletion } from '@/shared/hooks/useRequestData/useRequestDeletion';
import { useRequestUpdate } from '@/shared/hooks/useRequestData/useRequestUpdate';
import removeEmptyParams from '@/utils/removeEmptyParams';
import { turnIntoFormData } from '@/utils/turnIntoFormData';
import React, { useEffect, useMemo } from 'react';
import { Button, Dialog, Text } from 'vkit/lib/components';
import { Grid, Tooltip } from 'vkit/lib/context';

interface BannerManagerModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  assetImage?: AssetImage;
  refreshData?: () => void;
}

const BannerManagerModal: React.FC<BannerManagerModalProps> = ({
  open,
  setOpen,
  assetImage,
  refreshData,
}) => {
  const refDialog = React.useRef({ open: () => ({}), dismiss: () => ({}) });
  const refForm = React.useRef<FormValidateHandler>(null);

  const { checkUserHasPermission } = useCurrentUser();
  const { toast } = ToastStatusGlobal();

  const canCreate = checkUserHasPermission(USER_PERMISSIONS.ASSET_IMAGE_DELETE);
  const canUpdate = checkUserHasPermission(USER_PERMISSIONS.ASSET_IMAGE_UPDATE);
  const canDelete = checkUserHasPermission(USER_PERMISSIONS.ASSET_IMAGE_DELETE);

  const tooltipMessage = useMemo(() => {
    if (assetImage) {
      return canUpdate ? undefined : 'Você não tem permissão para alterar este banner';
    }

    return canCreate ? undefined : 'Você não tem permissão para registrar um banner';
  }, [assetImage, canCreate, canUpdate]);

  const { requestCreate, loading: loadingCreation } = useRequestCreation({
    request: (assetImage: AssetImage) =>
      new AssetImagesService().createAssetImage(turnIntoFormData(removeEmptyParams(assetImage))),
    callback: () => {
      toast('Feito', 'Novo banner registrado.', 'success');
      refreshData?.();
      setOpen(false);
    },
  });
  const { requestDeletion, loading: loadingDeletion } = useRequestDeletion({
    request: (assetImageId) => new AssetImagesService().deleteAssetImage(assetImageId),
    callback: () => {
      toast('Feito', 'Banner deletado.', 'success');
      refreshData?.();
      setOpen(false);
    },
  });
  const { requestUpdate, loading: loadingUpdate } = useRequestUpdate({
    request: (assetImageId: string | number, assetImage: Partial<AssetImage>) =>
      new AssetImagesService().updateAssetImage(
        assetImageId,
        turnIntoFormData(removeEmptyParams(assetImage)),
      ),
    callback: () => {
      toast('Feito', 'Banner alterado.', 'success');
      refreshData?.();
      setOpen(false);
    },
  });

  useEffect(() => {
    open ? refDialog.current.open() : refDialog.current.dismiss();
  }, [open]);

  return (
    <Dialog
      elevation={4}
      instanceEmitter={refDialog.current}
      outlined
      rounded
      size={800}
      onClose={() => setOpen(false)}
      head={<Text size='larger' textAlign='center' title value='Banner' />}
      footer={
        <Grid alignContent='justify'>
          <Button solo outlined label='Fechar' onClick={() => refDialog.current.dismiss()} />
          <Grid gap={8}>
            <Tooltip
              title={canDelete ? undefined : 'Você não tem permissão para remover este banner'}>
              <ButtonDialog
                text='Quer mesmo remover este banner?'
                color='danger'
                outlined
                label='Remover'
                disabled={!assetImage || !canDelete}
                loading={loadingDeletion}
                onClick={async () => {
                  if (!assetImage) {
                    return;
                  }
                  await requestDeletion(assetImage.id);
                }}
              />
            </Tooltip>

            <Tooltip title={tooltipMessage}>
              <Button
                outlined
                loading={loadingCreation || loadingUpdate}
                label={assetImage?.id ? 'Alterar' : 'Registrar'}
                disabled={assetImage?.id ? !canCreate : !canUpdate}
                onClick={() => refForm.current?.submit()}
              />
            </Tooltip>
          </Grid>
        </Grid>
      }>
      <BannerForm
        banner={assetImage}
        refreshData={refreshData}
        setOpen={setOpen}
        refForm={refForm}
        requestCreation={requestCreate}
        requestUpdate={requestUpdate}
      />
    </Dialog>
  );
};

export default BannerManagerModal;
