import { GridGroup, SelectValidate, TextFieldValidate } from '@/components';
import { ObjectType } from '@/shareds/types';
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { Checkbox } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';
import { Address, ADDRESS, ADDRESS_LABEL, ADDRESS_NAME } from '../../entities';
import { useAddressFinder } from '../../hooks';
import { getStateOptions } from '../../services';

interface AddressFormProps {
  values: Address;
  onBlur?: Function;
  useErrors?: ObjectType;
  onChange: Function;
  setDisabled: Dispatch<SetStateAction<Partial<Record<ADDRESS, boolean>>>>;
  disabled: Partial<Record<ADDRESS, boolean>>;
}

const Form: React.FC<AddressFormProps> = ({ values, onBlur, useErrors, onChange, disabled, setDisabled }) => {
  const [ disabledNumber, setDisabledNumber ] = useState(false)
  const { loading, searchAddress } = useAddressFinder({
    searchAddressCallback: (foundAddress, zipcode) => {
      onChange({
        [ADDRESS.CITY]: foundAddress.city,
        [ADDRESS.NEIGHBORHOOD]: foundAddress.neighborhood,
        [ADDRESS.LOCATION]: foundAddress.location,
        [ADDRESS.STATE_ABBR]: foundAddress.state_abbr,
      });

      const canDisable = zipcode.length !== 8;

      setDisabled({
        [ADDRESS.CITY]: canDisable || Boolean(foundAddress.city),
        [ADDRESS.NEIGHBORHOOD]: canDisable || Boolean(foundAddress.neighborhood),
        [ADDRESS.LOCATION]: canDisable || Boolean(foundAddress.location),
        [ADDRESS.STATE_ABBR]: canDisable || Boolean(foundAddress.state_abbr),
        [ADDRESS.NUMBER]: canDisable,
        [ADDRESS.COMPLEMENT]: canDisable,
      });
    },
  });

  const useValues = values as ObjectType;

  const handleChange = useCallback(
    (name: string, value: string) => {
      if (value !== useValues[name]) {
        onChange(name, value);
      }
    },
    [onChange, useValues],
  );

  return (
    <GridGroup
      body={[
        {
          default: 25,
          component: (
            <TextFieldValidate
              label={ADDRESS_LABEL.ZIPCODE}
              name={ADDRESS_NAME.ZIPCODE}
              onBlur={onBlur}
              onChange={(name: string, value: string) => {
                onChange(name, value);
                searchAddress(value);
              }}
              required
              useErrors={useErrors}
              useValues={useValues}
              mask='99999-999'
              loading={loading}
            />
          ),
        },
        {
          default: 55,
          component: (
            <TextFieldValidate
              required
              label={ADDRESS_LABEL.LOCATION}
              name={ADDRESS_NAME.LOCATION}
              useValues={useValues}
              useErrors={useErrors}
              onChange={handleChange}
              onBlur={onBlur}
              loading={loading}
              disabled={disabled[ADDRESS.LOCATION]}
            />
          ),
        },
        {
          default: 20,
          component: (
            <Grid column gap={8}>
              <TextFieldValidate
                label={ADDRESS_LABEL.NUMBER}
                name={ADDRESS_NAME.NUMBER}
                useValues={useValues}
                useErrors={useErrors}
                onChange={onChange}
                onBlur={onBlur}
                loading={loading}
                disabled={disabledNumber || disabled[ADDRESS.NUMBER]}
              />
              <Checkbox
                small
                label='Endereço sem número'
                checked={disabledNumber}
                onChange={(checked: boolean) => {
                  setDisabledNumber(checked);
                  onChange(ADDRESS_NAME.NUMBER, '');
                }}
              />
            </Grid>
          ),
        },
        {
          default: 40,
          component: (
            <TextFieldValidate
              required
              label={ADDRESS_LABEL.NEIGHBORHOOD}
              name={ADDRESS_NAME.NEIGHBORHOOD}
              useValues={useValues}
              useErrors={useErrors}
              onChange={handleChange}
              onBlur={onBlur}
              loading={loading}
              disabled={disabled[ADDRESS.NEIGHBORHOOD]}
            />
          ),
        },
        {
          default: 40,
          component: (
            <TextFieldValidate
              required
              label={ADDRESS_LABEL.CITY}
              name={ADDRESS_NAME.CITY}
              useValues={useValues}
              useErrors={useErrors}
              onChange={handleChange}
              onBlur={onBlur}
              loading={loading}
              disabled={disabled[ADDRESS.CITY]}
            />
          ),
        },
        {
          default: 20,
          component: (
            <SelectValidate
              required
              searchable
              label={ADDRESS_LABEL.STATE_ABBR}
              name={ADDRESS_NAME.STATE_ABBR}
              useValues={useValues}
              useErrors={useErrors}
              onChange={handleChange}
              onBlur={onBlur}
              data={getStateOptions()}
              loading={loading}
              disabled={disabled[ADDRESS.STATE_ABBR]}
              key={useValues[ADDRESS_NAME.STATE_ABBR]}
            />
          ),
        },
        {
          default: 100,
          middle: 100,
          component: (
            <TextFieldValidate
              label={ADDRESS_LABEL.COMPLEMENT}
              name={ADDRESS_NAME.COMPLEMENT}
              useValues={useValues}
              useErrors={useErrors}
              onChange={onChange}
              onBlur={onBlur}
              loading={loading}
              disabled={disabled[ADDRESS.COMPLEMENT]}
            />
          ),
        },
      ]}
    />
  );
};

export default Form;
