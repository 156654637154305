import { AssetImage } from '@/modules/assetImage/entities/interfaces';
import { BaseListResponse, SearchParams } from '@/shared/entities';
import HttpClientAdapter from '@/shared/services/httpClientAdapter';

class AssetImagesService extends HttpClientAdapter {
  constructor() {
    super('SYSTEM');
  }

  createAssetImage(assetImage: FormData) {
    return this.post('/asset-images', assetImage);
  }

  listAssetImages(filters?: Partial<SearchParams<AssetImage>>) {
    return this.get<BaseListResponse<AssetImage>>('/asset-images', filters);
  }

  showAssetImage(id: string | number) {
    return this.get(`/asset-images/${id}`);
  }

  updateAssetImage(id: string | number, assetImage: FormData) {
    return this.put(`/asset-images/${id}`, assetImage);
  }

  deleteAssetImage(id: string | number) {
    return this.delete(`/asset-images/${id}`);
  }
}

export default AssetImagesService;
