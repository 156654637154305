import { isSimilarStringLocaleCompare } from '@/utils/validations/isSimilarStringLocaleCompare';

type SexType = 'Masculino' | 'Feminino' | 'Não informado';

type StatusMaritalType =
  | 'Casado(a)'
  | 'Divorciado(a)'
  | 'Outro'
  | 'Relacionamento estável'
  | 'Solteiro(a)'
  | 'Viúvo(a)'
  | 'Não informado';

type RelationshipType = 'Cônjuge' | 'Filho(a)' | 'Pai/Mãe' | 'Não informado';

type ActionMoveType = 'Registro' | 'Atualização' | 'Cancelamento';

type subtitleType = {
  actionMove: { [key: string]: ActionMoveType };
  marital: { [key: string]: StatusMaritalType };
  relationship: { [key: string]: RelationshipType };
  sex: { [key: string]: SexType };
  boolean: { [key: string]: string };
};

const subtitle: subtitleType = {
  actionMove: {
    create: 'Registro',
    update: 'Atualização',
    delete: 'Cancelamento',
  },

  sex: {
    male: 'Masculino',
    female: 'Feminino',
    m: 'Masculino',
    f: 'Feminino',
  },

  marital: {
    single: 'Solteiro(a)',
    solteiro: 'Solteiro(a)',
    s: 'Solteiro(a)',

    married: 'Casado(a)',
    casado: 'Casado(a)',
    c: 'Casado(a)',

    divorced: 'Divorciado(a)',
    divorciado: 'Divorciado(a)',
    desquitado: 'Divorciado(a)',
    d: 'Divorciado(a)',
    separado: 'Divorciado(a)',

    widowed: 'Viúvo(a)',
    ['viúvo']: 'Viúvo(a)',
    v: 'Viúvo(a)',

    'stable-relationship': 'Relacionamento estável',
    ['união estável']: 'Relacionamento estável',

    outros: 'Outro',
    o: 'Outro',
  },

  relationship: {
    parent: 'Pai/Mãe',
    child: 'Filho(a)',
    spouse: 'Cônjuge',
  },

  boolean: {
    true: 'Sim',
    false: 'Não',
  },
};

const getTranslation = <T>(key: string, subtitles: Record<string, T>) => {
  for (const [subtitleKey, subtitleValue] of Object.entries(subtitles)) {
    if (isSimilarStringLocaleCompare(key, subtitleKey)) {
      return subtitleValue;
    }
  }
}

const translate = {
  actionMove: (key: string) => getTranslation(key, subtitle.actionMove),
  marital: (key: string) => getTranslation(key, subtitle.marital),
  relationship: (key: string) => getTranslation(key, subtitle.relationship),
  sex: (key: string) => getTranslation(key, subtitle.sex),
  boolean: (key: string)=> getTranslation(key, subtitle.boolean),
};

export default translate;
