import { NegotiationSearchParams } from '@/modules/negotiation';
import HttpClientAdapter from '@/shared/services/httpClientAdapter';
import { Negotiation } from '@/shareds/types/negotiation.type';

class NegotiationService extends HttpClientAdapter {
  constructor() {
    super('NEGOTIATION');
  }

  getNegotiations<T = any>(companyId: string, params: any) {
    const url = `/companies/${companyId}/negotiations`;
    return this.get<T>(url, params);
  }

  getAllNegotiationsByCompanyId<T = any>(companyId: string, params: any) {
    const url = `/companies/${companyId}/negotiations`;
    return this.getAll<T>(url, params);
  }

  getAllNegotiations(params: NegotiationSearchParams) {
    return this.getAll<Negotiation>('/negotiations', params);
  }

  getContractsActive<T = any>(companyId: string, params: any) {
    const url = `/companies/${companyId}/active-contracts`;
    return this.get<T>(url, params);
  }
}

export default NegotiationService;
