interface SubtitleRules {
  [key: string]: (value: string) => string;
}

const dateFormat = 'Formato DD/MM/YYYY';

const subtitleRules: SubtitleRules = {
  pad_date_ddmmyyyy: () => dateFormat,
  dateDDMMYYYY: () => dateFormat,
  dateFormat: () => dateFormat,
  is_cpf_valid: () => 'CPF válido com 11 caracteres',
  email: () => 'Formato de e-mail',
  length: (length: string) => `${length} caracteres`,
  max: (max: string) => `Máximo de ${max}`,
  maxLength: (maxLength: string) => `Máximo de ${maxLength} caracteres`,
  min: (min: string) => `Mínimo de ${min}`,
  minLength: (minLength: string) => `Mínimo de ${minLength} caracteres`,
  number: () => 'Formato numérico',
  pattern: (pattern: string) => `Formato: ${pattern}`,
  required: () => 'Campo obrigatório',
  stringMax: (max: string) => `Máximo de ${max} caracteres`,
  stringMin: (min: string) => `Mínimo de ${min} caracteres`,
  stringNumeric: () => 'Apenas números',
  stringLength: (length: string) => `${length} caracteres`,
  exclusive_translate: (value: string) => `Uma dessas opções: ${value}`,
  enumValue: (value: string) => `Uma dessas opções: ${value}`,
  minDate: (value: string) => `Data mínima: ${value}`,
  datePast: () => 'Data no passado',
  minAmountNumbers: (minLength: string) => `Mínimo de ${minLength} números`,
  maxAmountNumbers: (maxLength: string) => `Máximo de ${maxLength} números`,
  exactAmountNumbers: (exactLength: string) => `deve ter ${exactLength} números`,
  negotiationDataBelongsToCompanyGroup: () => 'Não foi possível encontrar a combinação desses valores entre os contratos relacionados a operadora',
};

const translateRulesValidator = (
  rule: string,
  expectedValue?: string | string[] | number,
): string => {
  if (rule in subtitleRules) {
    const value = Array.isArray(expectedValue) ? expectedValue.join(', ') : expectedValue;
    return subtitleRules[rule]?.(String(value));
  }

  return '';
};

export default translateRulesValidator;
