import { BlockingMessage } from '@/components';
import AlertMessage from '@/pages/beneficiaries/removal/components/alertMessage';
import InfoBeneficiary from '@/pages/beneficiaries/removal/components/infoBeneficiary';
import RemovalForm from '@/pages/beneficiaries/removal/components/removalForm';
import useRemoval from '@/pages/beneficiaries/removal/hooks/useRemoval';
import { get } from 'lodash';
import React from 'react';
import { Box, Grid, Row } from 'vkit/lib/context';

const Removal: React.FC = () => {
  const { beneficiary, beneficiaryHolder, negotiation, loading, canRemove } = useRemoval();

  return (
    <>
      {canRemove.can || loading.infos ? (
        <Row
          style={{
            padding: 0,
            gap: 16,
          }}>
          <AlertMessage
            show={!loading.infos}
            benefitName={get(negotiation, 'product.benefit.name', '')}
          />

          <InfoBeneficiary
            loading={loading.infos}
            beneficiary={beneficiary}
            beneficiaryHolder={beneficiaryHolder}
          />

          <RemovalForm
            beneficiary={beneficiary}
            beneficiaryHolder={beneficiaryHolder}
            negotiation={negotiation}
          />
        </Row>
      ) : (
        <Box elevation={4} type='sheet' outlined rounded>
          <Grid alignContent='center' alignItems='center' padding='0 0 32px'>
            <BlockingMessage
              vertical
              title='Esse beneficiário não pode ser excluído'
              description={canRemove.reasonToProhibit}
            />
          </Grid>
        </Box>
      )}
    </>
  );
};

export default Removal;
