import { BoxInfo } from '@/components';
import { PlanWithNegotiation } from '@/modules/plan/entities/plan';
import PlanList from '@/modules/plan/views/planList/planList';
import ICONS from '@/shareds/constants/icons';
import React from 'react';

interface PlansProps {
  data: PlanWithNegotiation[];
}

const Plans: React.FC<PlansProps> = ({ data = [] }) => {
  return (
    <BoxInfo title='Planos' iconImageUrl={ICONS.PLAN}>
      <PlanList
        data={data}
        elevation={0}
        columns={['name', 'ansCode', 'costCenter', 'accommodation', 'coverage']}
        actions={['goToDetails']}
      />
    </BoxInfo>
  );
};

export default Plans;
