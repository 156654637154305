import { BoxInfo, ShowHide } from '@/components';
import useCompanyApplication from '@/core/hooks/useCompanyApplication';
import { findRoutePathByName } from '@/core/utils';
import { BENEFICIARY_STATUS_REFER } from '@/modules/beneficiary/entities';
import { ROUTE_MOVES } from '@/modules/move/routes/moveRoute';
import { useHistoryNavigator } from '@/navigation';
import { analytics } from '@/shared/telemetry/telemetry';
import ICONS from '@/shareds/constants/icons';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { Button, Dialog, Text } from 'vkit/lib/components';
import { Grid, Row } from 'vkit/lib/context';
import { ROUTES_BENEFICIARY } from '../../routes';
import SelectBeneficiary from '../selectBeneficiary/selectBeneficiary';

interface HubActionsModalProps {
  isOpen: boolean;
  callbackAction: (value: boolean) => void;
}

const HubActionsModal: React.FC<HubActionsModalProps> = ({ isOpen, callbackAction }) => {
  // Sonarqube possúi uma regra que funções não devem ser vazias.
  // https://sonar.ci.rede.vitta.me/coding_rules?open=typescript%3AS1186&rule_key=typescript%3AS1186
  const useRefDialog = useRef({
    open: () => {
      // Referencia da função de "abrir" o modal, não sendo necessário nenhuma implementação.
    },
    dismiss: () => {
      // Referencia da função de "fechar" o modal, não sendo necessário nenhuma implementação.
    },
  });
  const navigate = useHistoryNavigator();
  const { companyId } = useCompanyApplication();
  const [selectBeneficiary, setSelectBeneficiary] = useState<boolean>(false);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState<
    Record<string, string | undefined>
  >({
    beneficiary: undefined,
  });
  const [error, setError] = useState<Record<string, string | undefined>>({
    beneficiary: undefined,
  });
  const isDisabledActions = useMemo(() => !selectedBeneficiary?.beneficiary, [selectedBeneficiary]);

  const handleGoBackAction = () => {
    setSelectBeneficiary(false);
    setSelectedBeneficiary({
      beneficiary: undefined,
    });
  };

  const handleCloseHub = () => {
    analytics.track('[END] - Hub Beneficiários');
    handleGoBackAction();
    callbackAction(false);
  };

  const onClickSendSheet = () => {
    const moveUploadRoute = findRoutePathByName(ROUTE_MOVES.UPLOAD);
    if (moveUploadRoute) {
      analytics.track('[HUB] - Movimentação Massiva');
      callbackAction(false);
      navigate.push(generatePath(moveUploadRoute, { companyId }));
    }
  };

  const handleOnChange = (name: string, value?: string) => {
    if (value) {
      setError({
        beneficiary: undefined,
      });
    }
    setSelectedBeneficiary({ [name]: value });
  };

  const handleOnBlur = () => {
    if (!selectedBeneficiary?.beneficiary) {
      setError({
        beneficiary: 'Campo obrigatório',
      });
    } else {
      setError({
        beneficiary: undefined,
      });
    }
  };

  const onClickCreateBeneficiary = () => {
    const beneficiaryCretateRouteFinded = findRoutePathByName(ROUTES_BENEFICIARY.CREATE);
    if (beneficiaryCretateRouteFinded) {
      analytics.track('[HUB] - [Criação] - Movimentação unitária');
      handleCloseHub();
      navigate.push(generatePath(beneficiaryCretateRouteFinded, { companyId }));
    }
  };

  const onClickUpdateOrRemoveBeneficiary = (isRemoval = false) => {
    const beneficiaryUpdateRouteFinded = findRoutePathByName(ROUTES_BENEFICIARY.UPDATE);
    const beneficiaryRemoveRouteFinded = findRoutePathByName(ROUTES_BENEFICIARY.REMOVE);
    const routeParams = {
      companyId,
      beneficiaryId: selectedBeneficiary.beneficiary,
    };
    if (selectedBeneficiary?.beneficiary) {
      if (isRemoval && beneficiaryRemoveRouteFinded) {
        analytics.track('[HUB] - [Remoção] - Movimentação unitária');
        handleCloseHub();
        return navigate.push(generatePath(beneficiaryRemoveRouteFinded, routeParams));
      }
      if (beneficiaryUpdateRouteFinded) {
        analytics.track('[HUB] - [Alteração] - Movimentação unitária');
        handleCloseHub();
        return navigate.push(generatePath(beneficiaryUpdateRouteFinded, routeParams));
      }
    }
  };

  useEffect(() => {
    if (open !== undefined) {
      isOpen ? useRefDialog.current.open() : useRefDialog.current.dismiss();
    }
  }, [isOpen]);

  return (
    <Dialog
      shadow
      outlined
      rounded
      instanceEmitter={useRefDialog.current}
      elevation={2}
      size={1024}
      persistent
      title={
        <Text
          value='Atualizar beneficiários'
          title
          size='larger'
          icon='people-outline'
          iconColor='colorDefault'
          color='colorDefault'
          iconSize={32}
          textAlign='left'
        />
      }
      body={
        <Row>
          <ShowHide transition='slideToDown' visible={!selectBeneficiary}>
            <Grid gap={48} growing stretch>
              <BoxInfo icon={ICONS.BENEFICIARIES} title='Gerenciamento individual'>
                <Text
                  value='Realize ações individuais. Inclua, altere ou exclua via formulário.'
                  size='medium'
                />
                <Row style={{ padding: '24px 0 0' }}>
                  <Grid alignContent='right' gap={12}>
                    <Button label='Incluir' onClick={onClickCreateBeneficiary} />
                    <Button
                      label='Alterar / Excluir'
                      onClick={() => setSelectBeneficiary(!selectBeneficiary)}
                    />
                  </Grid>
                </Row>
              </BoxInfo>
              <BoxInfo icon={ICONS.MOVE} title='Gerenciamento massivo'>
                <Text
                  value='Realize ações em massa. Inclua, remova ou exclua vários beneficiários simultaneamente.'
                  size='medium'
                />
                <Row style={{ padding: '24px 0 0' }}>
                  <Grid alignContent='right' gap={12}>
                    <Button label='Enviar layout' onClick={onClickSendSheet} />
                  </Grid>
                </Row>
              </BoxInfo>
            </Grid>
          </ShowHide>
          <ShowHide transition='slideToDown' visible={selectBeneficiary}>
            <BoxInfo
              icon={ICONS.BENEFICIARIES}
              title='Selecione o beneficiário para alterar ou excluir'>
              <Row style={{ padding: '0 0 24px' }}>
                <SelectBeneficiary
                  autoRequest
                  name='beneficiary'
                  label='Qual beneficiário deseja alterar ou excluir?'
                  required
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  useErrors={error}
                  useValues={selectedBeneficiary}
                  filterParams={{
                    status: BENEFICIARY_STATUS_REFER.ACTIVE
                  }}
                />
              </Row>
              <Row style={{ padding: 0 }}>
                <Grid alignContent='right' gap={24}>
                  <Button
                    disabled={isDisabledActions}
                    label='Alterar'
                    size='medium'
                    onClick={() => onClickUpdateOrRemoveBeneficiary()}
                  />
                  <Button
                    disabled={isDisabledActions}
                    label='Excluir'
                    color='danger'
                    size='medium'
                    onClick={() => onClickUpdateOrRemoveBeneficiary(true)}
                  />
                </Grid>
              </Row>
            </BoxInfo>
          </ShowHide>
        </Row>
      }
      footer={
        <Grid alignContent='right' alignItems='center' gap={24}>
          {selectBeneficiary && (
            <Button
              label='Voltar'
              iconColor='light'
              size='medium'
              color='light'
              solo
              onClick={handleGoBackAction}
            />
          )}
          <Button
            label='Fechar'
            icon='close'
            iconColor='colorDefault'
            size='medium'
            color='colorDefault'
            outlined
            solo
            onClick={handleCloseHub}
          />
        </Grid>
      }
    />
  );
};

export default HubActionsModal;
