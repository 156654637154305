import { tryOrCatchMessageError } from '@/utils/tryOrCatchMessageError';
import { useCallback, useState } from 'react';

export interface UseRequestUpdateOptions<Entity, Response> {
  request: (
    id: string | number,
    entity: Partial<Entity>,
    companyId?: string,
  ) => Promise<Response> | Response;
  externalLoading?: boolean;
  callback?: (response: Response, entity: Partial<Entity>, companyId?: string) => void;
}

export const useRequestUpdate = <Entity, Response = void>({
  request,
  externalLoading,
  callback,
}: UseRequestUpdateOptions<Entity, Response>) => {
  const [loading, setLoading] = useState(false);

  const requestUpdate = useCallback(
    async (id: string | number, entity: Partial<Entity>, companyIdToUpdate?: string) => {
      if (externalLoading || loading) {
        return;
      }

      await tryOrCatchMessageError(
        async () => {
          const response = await request(id, entity, companyIdToUpdate);
          callback?.(response, entity, companyIdToUpdate);
        },
        {
          tryBefore: () => setLoading(true),
          tryAfter: () => setLoading(false),
          messageErrorDefault: 'A solicitação de alteração falhou, tente novamente mais tarde.',
        },
      );
    },
    [externalLoading, callback, request, loading],
  );

  return {
    requestUpdate,
    loading,
  };
};
