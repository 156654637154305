import { Negotiation } from '@/services/negotiations/endpoints/negotiations';
import { get } from 'lodash';

const { REACT_APP_PROVIDER_BY_ID = '{}' } = process.env;

const providerById = JSON.parse(REACT_APP_PROVIDER_BY_ID);

const isProvider = (negotiation: Negotiation, providerName: string) => {
  const providerId = get(negotiation, 'product.providerId');

  if (!providerId) {
    return false;
  }

  return providerById[providerId] === providerName;
};

export const isBradesco = (negotiation: Negotiation) => isProvider(negotiation, 'bradesco');
export const isOmni = (negotiation: Negotiation) => isProvider(negotiation, 'omni');
