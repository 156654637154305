import { AssetImage } from '@/modules/assetImage/entities/interfaces';
import { useCallback } from 'react';

interface UseBannerValidattionProps {
  banner?: Partial<AssetImage>;
}

export const useBannerValidation = ({ banner }: UseBannerValidattionProps) => {
  const isAfterNow = useCallback((value: string) => {
    if (!value) {
      return null;
    }

    const isValid = new Date(value).getTime() > new Date().getTime();

    return isValid ? null : 'Deve ser uma data futura';
  }, []);

  const isAfterInitialDate = useCallback(
    (value: string) => {
      if (!banner?.initialDate || !value) {
        return null;
      }

      const isValid = new Date(value).getTime() > new Date(banner.initialDate).getTime();

      return isValid ? null : 'Deve ser uma data depois do início de exibição';
    },
    [banner?.initialDate],
  );

  const isBeforeEndDate = useCallback(
    (value: string) => {
      if (!banner?.endDate || !value) {
        return null;
      }

      const isValid = new Date(value).getTime() < new Date(banner.endDate).getTime();

      return isValid ? null : 'Deve ser uma data antes do fim de exibição';
    },
    [banner?.endDate],
  );

  return {
    isAfterNow,
    isAfterInitialDate,
    isBeforeEndDate,
  };
};
