import { tryOrCatchMessageError } from '@/utils/tryOrCatchMessageError';
import { useCallback, useState } from 'react';

export interface UseRequestDeleteOptions<Response> {
  request: (id: string | number, companyId?: string) => Promise<Response> | Response;
  externalLoading?: boolean;
  callback?: (response: Response, id: string | number, companyId?: string) => Response;
}

export const useRequestDeletion = <Response = void>({
  request,
  externalLoading,
  callback,
}: UseRequestDeleteOptions<Response>) => {
  const [loading, setLoading] = useState(false);

  const requestDeletion = useCallback(
    async (id: string | number, companyIdToCreate?: string) => {
      if (externalLoading) {
        return;
      }

      await tryOrCatchMessageError(
        async () => {
          const response = await request(id, companyIdToCreate);
          callback?.(response, id, companyIdToCreate);
        },
        {
          tryBefore: () => setLoading(true),
          tryAfter: () => setLoading(false),
          messageErrorDefault: 'A solicitação de deleção falhou, tente novamente mais tarde.',
        },
      );
    },
    [externalLoading, request, callback],
  );

  return {
    requestDeletion,
    loading,
  };
};
