import { COMPANY_PERMISSION } from '@/core/entities/interfaces/permissions';
import { ObjectType } from '@/shareds/types';
import { User } from './user';

export enum INITIAL_PAGINATION_PARAMS {
  PAGE = 1,
  PAGE_SIZE = 10,
}

export enum USER_TYPE {
  BROKER = 'broker',
  COMPANY = 'company',
}

export enum FORM_SCOPE_TYPE {
  BROKER = 'broker',
  COMPANY = 'company',
}

export enum USER_STATUS {
  PENDING = 'pending',
  ACTIVE = 'active',
  BLOCKED = 'blocked',
  INACTIVATED = 'deleted',
}

export const USER_STATUS_TRANSLATED: Record<USER_STATUS, string> = Object.freeze({
  [USER_STATUS.PENDING]: 'Aguardando confirmação',
  [USER_STATUS.ACTIVE]: 'Ativo',
  [USER_STATUS.BLOCKED]: 'Bloqueado',
  [USER_STATUS.INACTIVATED]: 'Inativo',
});

export enum USER_AVAILABLE_FILTERS {
  NAME_EMAIL = 'name-email',
  STATUS = 'status',
}

export enum USER_PERMISSIONS {
  BROKER_USER_CREATE = 'broker_user:create',
  BROKER_USER_READ = 'broker_user:read',
  BROKER_USER_UPDATE = 'broker_user:update',
  BROKER_USER_DELETE = 'broker_user:delete',
  COMPANY_USER_CREATE = 'company_user:create',
  COMPANY_USER_READ = 'company_user:read',
  COMPANY_USER_UPDATE = 'company_user:update',
  COMPANY_USER_DELETE = 'company_user:delete',
  ASSET_IMAGE_CREATE = 'asset_image:create',
  ASSET_IMAGE_READ = 'asset_image:read',
  ASSET_IMAGE_UPDATE = 'asset_image:update',
  ASSET_IMAGE_DELETE = 'asset_image:delete',
}

export enum USER_AVAILABLE_TABLE_ACTIONS {
  DETAILS = 'details',
}

export enum USER_AVAILABLE_TABLE_COLUMNS {
  NAME = 'name',
  EMAIL = 'email',
  STATUS = 'status',
  CREATED_AT = 'created_at',
}

export const MAXLENGTH_USER_FORM = 120;

export const USER_INITIAL_STATE: User = Object.freeze({
  name: '',
  email: '',
  type: USER_TYPE.COMPANY,
  status: USER_STATUS.PENDING,
  scopeIds: [],
  permissions: [],
  userPermissions: [],
});

export const USER_BROKER_INITIAL_STATE = Object.freeze({
  name: '',
  email: '',
  type: USER_TYPE.BROKER,
  status: USER_STATUS.PENDING,
  scopeIds: [],
  permissions: [
    COMPANY_PERMISSION.COMPANY_USER_CREATE,
    COMPANY_PERMISSION.COMPANY_USER_DELETE,
    COMPANY_PERMISSION.COMPANY_USER_READ,
    COMPANY_PERMISSION.COMPANY_USER_UPDATE,
    COMPANY_PERMISSION.MOVE_CREATE,
    COMPANY_PERMISSION.COMPANY_USER_UNBLOCK,
  ],
  userPermissions: [],
});

export const USER_COMPANY_INITIAL_STATE = Object.freeze({
  name: '',
  email: '',
  type: USER_TYPE.COMPANY,
  status: USER_STATUS.PENDING,
  scopeIds: [],
  permissions: [
    COMPANY_PERMISSION.COMPANY_USER_CREATE,
    COMPANY_PERMISSION.COMPANY_USER_DELETE,
    COMPANY_PERMISSION.COMPANY_USER_READ,
    COMPANY_PERMISSION.COMPANY_USER_UPDATE,
    COMPANY_PERMISSION.MOVE_CREATE,
  ],
  userPermissions: [],
});

export const userMessageErrorByMessageErrorRequest: ObjectType = {
  'E-mail address already registered for another user':
    'Endereço de e-mail já registrado por outro usuário',
};

export const TOTP_RESET_ERRORS: ObjectType = Object.freeze({
  'E-mail address already registered for another user':
    'Endereço de e-mail já registrado por outro usuário.',
  'User already has pending confirmation': 'Usuário já tem uma confirmação pendente.',
  'You cannot reset unconfirmed user totp':
    'Você não pode redefinir autenticação em dois fatores para um usuário não confirmado.',
});
