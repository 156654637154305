import { get } from 'lodash';
import React from 'react';
import slugify from 'slugify';
import { BoxInfo, IconBenefit, TextData } from '@/components';
import BenefitSlugType from '@/components/iconBenefit/types/benefitSlug.type';
import { Negotiation } from '@/modules/negotiation';
import { LogoProvider } from '@/modules/provider';
import { isOmni } from '@/pages/beneficiaries/shareds/utils/checkProvider';
import { BeneficiaryNormalized } from '../../entities';

interface BenefitDataProps {
  title?: string;
  icon?: string;
  negotiation?: Negotiation;
  beneficiary?: BeneficiaryNormalized;
}

const BenefitData: React.FC<BenefitDataProps> = ({
  title = 'Dados do benefício',
  icon = 'activity-outline',
  negotiation,
  beneficiary,
}) => {
  const product = get(negotiation, 'product', { name: '', providerInternalCode: '' });
  const provider = get(negotiation, 'product.provider');
  const benefit = get(negotiation, 'product.benefit');
  const contract = get(negotiation, 'contract', { code: '' });
  const costCenter = get(negotiation, 'costCenter', { name: '' });

  return (
    <BoxInfo title={title} icon={icon}>
      <TextData
        loading={false}
        data={[
          {
            label: 'Fornecedor',
            text: provider?.name,
            component: provider?.cnpj ? (
              <LogoProvider size='small' providerCnpj={String(provider.cnpj)} />
            ) : null,
            sizeMobile: 100,
            sizeMedium: 100,
            sizeDefault: 66.66,
          },
          {
            label: 'Benefício',
            text: benefit?.name,
            component: (
              <IconBenefit
                small
                benefitSlug={slugify(benefit?.name || '', { lower: true }) as BenefitSlugType}
              />
            ),
            sizeMobile: 100,
            sizeMedium: 100,
            sizeDefault: 33.33,
          },
          {
            label: 'Plano',
            text: negotiation && isOmni(negotiation) ? product.name : product.providerInternalCode || product.name,
          },
          {
            label: 'Código do contrato',
            text: contract?.code,
          },
          {
            label: 'Subfatura',
            text: costCenter?.name,
          },
          {
            label: 'Número do cartão',
            text: beneficiary?.healthCardNumber,
          },
          {
            label: 'Início da vigência',
            text: beneficiary?.validFrom,
            type: 'date',
          },
          {
            label: 'Fim da vigência',
            text: beneficiary?.validUntil || '',
            type: 'date',
          },
        ]}
      />
    </BoxInfo>
  );
};

export default BenefitData;
