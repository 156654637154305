import { ToastStatusGlobal } from '@/components';
import { useHistoryParams } from '@/navigation';
import { BeneficiaryWithDocuments } from '@/pages/beneficiaries/shareds/interfaces/beneficiary';
import { extractDocumentsFromBeneficiary } from '@/pages/beneficiaries/shareds/utils/extractDocumentsFromBeneficiary';
import { getBeneficiaryById } from '@/pages/beneficiaries/shareds/utils/getBeneficiaryById';
import { beneficiaryInitialState } from '@/services/beneficiaries/endpoints/beneficiaries';
import {
  Negotiation,
  negotiationInitalState,
  negotiationsService,
} from '@/services/negotiations/endpoints/negotiations';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import { ObjectType } from '@/shareds/types';
import { getMessageError } from '@/utils/getMessageError';
import { useCallback, useEffect, useMemo, useState } from 'react';

interface UseRemoval {
  beneficiary: BeneficiaryWithDocuments;
  beneficiaryHolder: BeneficiaryWithDocuments;
  negotiation: Negotiation;
  loading: { [key: string]: boolean };
  canRemove: {
    reasonToProhibit: string | null;
    can: boolean;
  };
}

const useRemoval = (): UseRemoval => {
  const [loading, setLoading] = useState<ObjectType>({});
  const [negotiation, setNegotiation] = useState<Negotiation>(negotiationInitalState);
  const [beneficiary, setBeneficiary] = useState<BeneficiaryWithDocuments>(beneficiaryInitialState);
  const [beneficiaryHolder, setBeneficiaryHolder] =
    useState<BeneficiaryWithDocuments>(beneficiaryInitialState);

  const { toast } = ToastStatusGlobal();
  const { company } = useCompany();
  const { beneficiaryId } = useHistoryParams();

  const hasBeneficiaryHolder = useMemo(
    () => beneficiary.beneficiaryHolder?.holderId !== beneficiary.id,
    [beneficiary.beneficiaryHolder?.holderId, beneficiary.id],
  );

  const canRemove = useMemo(() => {
    if (!negotiation) {
      return {
        reasonToProhibit: 'Não encontramos negociação desse beneficiário.<br />Favor encontrar com o time responsável da Vitta.',
        can: false,
      };
    }

    if (hasBeneficiaryHolder && !beneficiaryHolder.lifeDocuments?.cpf) {
      return {
        reasonToProhibit: 'Identificamos um problema ao vincular com o titular.<br />Favor encontrar com o time responsável da Vitta.',
        can: false,
      };
    }

    if (!hasBeneficiaryHolder && !beneficiary.lifeDocuments?.cpf) {
      return {
        reasonToProhibit: 'Não encontramos o CPF do titular.<br />Favor encontrar com o time responsável da Vitta.',
        can: false,
      };
    }

    return {
      reasonToProhibit: null,
      can: true,
    };
  }, [beneficiary.lifeDocuments?.cpf, beneficiaryHolder.lifeDocuments?.cpf, hasBeneficiaryHolder, negotiation]);

  const onLoading = (key: string, value: boolean) =>
    setLoading((oldData) => ({
      ...oldData,
      [key]: value,
    }));

  const getBeneficiaryWithDocuments = useCallback(
    async (beneficiaryIdToFound: string, companyIdToFound: string) => {
      let beneficiaryWithDocuments: BeneficiaryWithDocuments = beneficiaryInitialState;

      onLoading('infos', true);
      try {
        const beneficiaryFound = await getBeneficiaryById(beneficiaryIdToFound, companyIdToFound);

        if (!beneficiaryFound) {
          return beneficiaryInitialState;
        }

        const lifeDocuments = extractDocumentsFromBeneficiary(beneficiaryFound);

        beneficiaryWithDocuments = {
          ...beneficiaryFound,
          lifeDocuments,
        };
      } catch (error) {
        console.error(error);
        const messageError = getMessageError({
          error,
          messageDefault: 'Erro ao carregar o beneficiário.',
        });
        toast('Oops', messageError, 'error');
      } finally {
        onLoading('infos', false);
      }

      return beneficiaryWithDocuments;
    },
    [toast],
  );

  const getNegotiation = useCallback(
    async (negotiationId: string, companyId: string) => {
      onLoading('infos', true);
      try {
        const [negotiationFound] = await negotiationsService(companyId).list({
          id: negotiationId,
        });

        return negotiationFound;
      } catch (error) {
        console.error(error);
        const messageError = getMessageError({
          error,
          messageDefault: 'Erro ao carregar o negociações.',
        });
        toast('Oops', messageError, 'error');
      } finally {
        onLoading('infos', false);
      }

      return negotiationInitalState;
    },
    [toast],
  );

  useEffect(() => {
    (async () => {
      if (!company.id || !beneficiaryId) {
        return;
      }

      const beneficiaryWithDocumentsFound = await getBeneficiaryWithDocuments(
        beneficiaryId,
        company.id,
      );
      setBeneficiary(beneficiaryWithDocumentsFound);
    })();
  }, [beneficiaryId, company.id, getBeneficiaryWithDocuments]);

  useEffect(() => {
    (async () => {
      if (!hasBeneficiaryHolder || !beneficiary.beneficiaryHolder?.holderId || !company.id) {
        return;
      }

      const beneficiaryHolderWithDocumentsFound = await getBeneficiaryWithDocuments(
        beneficiary.beneficiaryHolder.holderId,
        company.id,
      );

      setBeneficiaryHolder(beneficiaryHolderWithDocumentsFound);
    })();
  }, [
    beneficiary.beneficiaryHolder?.holderId,
    beneficiaryId,
    company.id,
    getBeneficiaryWithDocuments,
    hasBeneficiaryHolder,
  ]);

  useEffect(() => {
    (async () => {
      if (!beneficiary.negotiationId || !company.id) {
        return;
      }

      const negotiationFound = await getNegotiation(beneficiary.negotiationId, company.id);
      setNegotiation(negotiationFound);
    })();
  }, [beneficiary.negotiationId, company.id, getNegotiation]);

  return {
    beneficiary,
    beneficiaryHolder,
    negotiation,
    loading,
    canRemove,
  };
};

export default useRemoval;
