import { AssetImage } from '@/modules/assetImage/entities/interfaces';

export const turnIntoFormData = (data: AssetImage) => {
  const formData = new FormData();

  for (const [key, value] of Object.entries(data)) {
    formData.append(key, value);
  }

  return formData;
};
