import { AssetImage } from '@/modules/assetImage/entities/interfaces';
import React, { useMemo } from 'react';
import { BoxInfo } from '@/components';
import { Row } from 'vkit/lib/context';
import { Link } from 'react-router-dom';

interface BannerProps {
  elevation?: number;
  margin?: string | number;
  assetImage?: AssetImage;
}

const BannerViwer: React.FC<BannerProps> = ({ elevation, margin, assetImage }) => {
  const showBanner = useMemo(() => {
    if (!assetImage) {
      return false;
    }

    const { initialDate, endDate } = assetImage;

    if (!initialDate || !new Date(initialDate).getTime()) {
      return false;
    }

    if (
      new Date(initialDate).getTime() <= new Date().getTime() &&
      (!endDate ||
        !new Date(endDate).getTime() ||
        new Date(endDate).getTime() >= new Date().getTime())
    ) {
      return true;
    }

    return false;
  }, [assetImage]);

  const redirect = assetImage?.link ?? '/';

  return showBanner ? (
    <Row style={{ padding: 0 }}>
      <Link to={redirect} target='_blank' rel='noopener noreferrer'>
        <BoxInfo
          elevation={elevation}
          margin={margin}
          inlineStyle={{
            backgroundImage: `url(${assetImage?.url})`,
            backgroundSize: 'content',
            height: '362px',
            position: 'relative',
          }}
        />
      </Link>
    </Row>
  ) : null;
};

export default BannerViwer;
