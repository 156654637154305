import React, { ReactNode } from 'react';

import style from './style/gridGroup.module.scss';

export interface GridGroupItemProps {
  component: ReactNode;
  default?: number;
  hide?: boolean;
  middle?: number;
  mobile?: number;
}

interface GridGroupProps {
  gap?: number;
  body: GridGroupItemProps[];
}

const GridGroup: React.FC<GridGroupProps> = ({ gap = 20, body }) => {
  const getWidth = (item: GridGroupItemProps): number => {
    if (window.innerWidth <= 768) {
      return item.mobile || 100;
    } else if (window.innerWidth <= 1024) {
      return item.middle || 50;
    }

    return item.default || 33.33;
  };

  return (
    <div
      className={style.gridGroup}
      style={{
        margin: `0 -${gap}px -${gap}px 0`,
      }}>
      {body.map((content, key) => (
        <div
          className={style.gridGroupItem}
          key={key}
          style={{
            display: content.hide ? 'none' : 'block',
            margin: `0 ${gap}px ${gap}px 0`,
            width: `calc(100% - ${gap}px)`,
            maxWidth: `calc(${getWidth(content)}% - ${gap}px)`,
          }}>
          {content.component}
        </div>
      ))}
    </div>
  );
};

export default GridGroup;
