import { getErrorDescription } from '@/pages/moves/upload/utils/getErrorDescription';
import { getFieldNames } from '@/pages/moves/upload/utils/utils';
import { MovesValidationErrorDetail } from '@/services/files/endpoints/moves';
import translateRulesValidator from '@/utils/translateRulesValidator';
import React, { useEffect, useRef } from 'react';
import { Button, DataTable, Dialog, Text } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';

interface ErrorTableModalProps {
  details: MovesValidationErrorDetail[];
}

const ErrorTableModal: React.FC<ErrorTableModalProps> = (
  {
    details
  },
) => {
  const useRefDialog = useRef({ open: () => ({}), dismiss: () => ({}) });

  useEffect(() => {
    if (details.length > 0) {
      useRefDialog.current.open();
      return;
    }

    useRefDialog.current.dismiss();
  }, [details.length]);

  return (
    <Dialog
      elevation={4}
      instanceEmitter={useRefDialog.current}
      outlined
      rounded
      size={800}
      footer={
        <Grid alignContent='right' gap={8}>
          <Button
            label='Fechar'
            color='colorDefault'
            onClick={() => useRefDialog.current.dismiss()}
          />
        </Grid>
      }
    >
      <Text
        applyBaseColor
        color='danger'
        fontWeight='bold'
        icon='alert-triangle-outline'
        iconColor='danger'
        margin='0 0 16px'
        padding='8px 16px'
        rounded
        size='larger'
        value={`Ops! Não conseguimos processar a sua solicitação de movimentação. 
        Detectamos algumas informações incorretas ou ausentes nos campos destacados na tabela abaixo. 
        Por favor, verifique os seguintes campos abaixo e tente novamente.`}
      />

      <DataTable
        isLoading={false}
        showPagination={false}
        elevation={4}
        data={details}
        columns={[
          {
            name: 'Campo',
            path: 'fields',
            format: (error) => getFieldNames(error),
          },
          {
            name: 'Valor encontrado',
            path: 'value',
          },
          {
            name: 'Valor esperado/problema',
            path: 'rule',
            format: (movesValidationErrorDetail: MovesValidationErrorDetail) =>
              translateRulesValidator(
                movesValidationErrorDetail.rule,
                movesValidationErrorDetail.details?.expected,
              ) || getErrorDescription(movesValidationErrorDetail),
          },
        ]}
      />
    </Dialog>
  );
};

export default ErrorTableModal;
