import { tryOrCatchMessageError } from '@/utils/tryOrCatchMessageError';
import { useCallback, useState } from 'react';

export interface UseRequestCreateOptions<Entity, Response = void> {
  request: (entity: Entity, companyId?: string) => Promise<Response> | Response;
  externalLoading?: boolean;
  callback?: (response: Response, entity: Entity, companyId?: string) => Response;
}

export const useRequestCreation = <Entity, Response>({
  request,
  externalLoading,
  callback,
}: UseRequestCreateOptions<Entity, Response>) => {
  const [loading, setLoading] = useState(false);

  const requestCreate = useCallback(
    async (entity: Entity, companyIdToCreation?: string) => {
      if (externalLoading) {
        return;
      }

      await tryOrCatchMessageError(
        async () => {
          const response = await request(entity, companyIdToCreation);
          callback?.(response, entity, companyIdToCreation);
        },
        {
          tryBefore: () => setLoading(true),
          tryAfter: () => setLoading(false),
          messageErrorDefault: 'A solicitação de registro falhou, tente novamente mais tarde.',
        },
      );
    },
    [externalLoading, callback, request],
  );

  return {
    requestCreate,
    loading,
  };
};
