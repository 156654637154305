import { FileUpload } from '@/components';
import ShowHide from '@/components/showHide';
import { ObjectType } from '@/shareds/types';
import React, { useState } from 'react';
import { Button } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';

interface BannerUploadProps {
  name: string;
  onChange: (...item: any) => void;
  onBlur: (...item: any) => void;
  useErrors: ObjectType;
  imageUrl?: string;
}

const BannerUpload: React.FC<BannerUploadProps> = ({
  name,
  onBlur,
  onChange,
  useErrors,
  imageUrl,
}) => {
  const [showCurrentImage, setShowCurrentImage] = useState(Boolean(imageUrl));
  return (
    <>
      <ShowHide visible={showCurrentImage} transition='slideToDown'>
        <img width='100%' height='auto' src={imageUrl} alt={'banner'} style={{ borderRadius: 8 }} />
        <Grid alignContent='right' stretch margin='16px 0 0'>
          <Button label='Alterar imagem' onClick={() => setShowCurrentImage(false)} />
        </Grid>
      </ShowHide>

      <ShowHide visible={!showCurrentImage} transition='slideToDown'>
        <FileUpload
          label='Upload da imagem'
          name={name}
          onChange={(name: string, value: File) => {
            onChange(name, value);
            onBlur(name, value);
          }}
          formValidatorErrors={useErrors}
          allowedExtensions={['jpg', 'jpeg', 'png']}
          required
        />

        {imageUrl && (
          <Grid alignContent='right' stretch margin='16px 0 0'>
            <Button label='Ver banner atual' onClick={() => setShowCurrentImage(true)} />
          </Grid>
        )}
      </ShowHide>
    </>
  );
};

export default BannerUpload;
