import React, { useCallback } from 'react';

import { DialogConfirm } from '@/components';
import useDialogConfirm from '@/components/dialogConfirm/hooks/useDialogConfirm';
import { Button } from 'vkit/lib/components';

import { Colors, Size } from '@/shareds/types';

interface ButtonDialogInterface {
  color?: Colors;
  label?: string;
  onClick?: (...item: any) => void;
  outlined?: boolean;
  size?: Size;
  solo?: boolean;
  text: string;
  icon?: string;
  iconColor?: Colors;
  modalType?: Colors;
  disabled?: boolean;
  loading?: boolean;
}

const ButtonDialog: React.FC<ButtonDialogInterface> = ({
  color,
  label,
  onClick,
  outlined,
  size,
  solo,
  text,
  icon,
  iconColor,
  modalType,
  disabled,
  loading,
}) => {
  const {
    useTitle: useDialogConfirmTitle,
    useText: useDialogConfirmText,
    useOpen: useDialogConfirmOpen,
    useFnCallback,
    useConfirm: confirm,
  } = useDialogConfirm();

  const onConfirmAction = useCallback(
    async (...args): Promise<void> => {
      const canDelete = await confirm('Atenção', text);

      if (canDelete) {
        onClick?.(...args);
      }
    },
    [onClick, confirm, text],
  );

  return (
    <>
      <DialogConfirm
        fnCallback={useFnCallback}
        open={!!useDialogConfirmOpen}
        text={useDialogConfirmText}
        title={useDialogConfirmTitle}
        modalType={modalType}
      />

      <Button
        outlined={outlined}
        solo={solo}
        color={color}
        size={size}
        label={label}
        onClick={onConfirmAction}
        icon={icon}
        iconColor={iconColor}
        disabled={disabled}
        loading={loading}
      />
    </>
  );
};

export default ButtonDialog;
